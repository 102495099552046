<template>
  <div class="home">
    <div v-if="props.type === 'MBTI测评'">
      <el-form
        ref="mbtiRef"
        :model="leaveState"
        :rules="mbtiRules"
        label-position="top"
      >
        <el-form-item label="等级名称" prop="title">
          <el-input v-model="leaveState.title"></el-input>
        </el-form-item>
        <el-form-item label="判定标准" prop="scales">
          <div v-infinite-scroll="getSubjectscaleList" class="infinite-list" style="overflow: auto" :infinite-scroll-immediate="false">
            <div v-for="item in state.dimensionList"
              :key="item.id"
            >
              <div>{{ item.title }}</div>
              <el-checkbox-group v-model="item.checkedScale" class="mbti-radio-group" :max="1">
                <el-checkbox
                  v-for="scale in item.scaleItems"
                  :key="scale.id"
                  :label="scale.id"
                >{{ scale.name }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="结果总描述" prop="pic">
          <el-upload
            class="banner-uploader"
            action
            :http-request="picUpload"
            :show-file-list="false"
            :before-upload="beforeUpload"
          >
            <el-image v-if="leaveState.pic" :src="leaveState.pic" class="banner-img" fit="cover"></el-image>
            <el-icon class="avatar-uploader-icon">
              <plus />
            </el-icon>
          </el-upload>
        </el-form-item>
        <el-form-item prop="description">
          <el-input
            type="textarea"
            v-model="leaveState.description"
            autosize
            placeholder=""
            class="textarea-padding"
          ></el-input>
          <span
            :class="{
              'error-count': leaveState.description?.length > 500
            }"
            class="count"
          >{{ leaveState.description?.length }} / 500</span>
        </el-form-item>
        <el-form-item label="等级测评建议" prop="list">
          <div v-for="v in leaveState.list"
            :key="v.key"
            class="list-item"
          >
            <div class="list-item-title">
              <el-input v-model="v.title" placeholder="请输入建议标题"></el-input>
              <el-button type="text" class="del-btn" @click="delIntroduce(v.key)">删除</el-button>
            </div>
            <div class="list-item-desc" style="position: relative;">
              <el-input
                type="textarea"
                :rows="4"
                v-model="v.value"
                placeholder="建议示例：一、多吃蔬菜；二、多喝热水"
                autosize
                class="textarea-padding"
              ></el-input>
              <span
                :class="{
                  'error-count': v.value?.length > 500
                }"
                class="count"
            >{{ v.value?.length }} / 500</span>
            </div>
          </div>
          <el-button type="primary" size="small" @click="addIntroduce">添加</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-else>
      <p>
        总等级名<span style="color: red; margin: 10px 0; display: inline-block"
          >*</span
        >
      </p>
      <el-input
        v-model="leaveState.title"
        placeholder="请输入题目"
        :maxlength="50"
      />
      <p>达标分
        <span style="color: red; margin: 10px 0; display: inline-block">*不同等级达标分不能相同</span>
      </p>
      <el-input-number
        style="width: 320px"
        v-model="leaveState.pass"
        :controls="false"
      />
      <p>结果总描述
        <span style="color: red; margin: 10px 0; display: inline-block">*</span>
      </p>
      <el-input
        v-model="leaveState.introduce"
        :maxlength="500"
        :rows="4"
        type="textarea"
        placeholder="请输入描述"
      />
      <p>等级测评建议
        <span style="color: red; margin: 10px 0; display: inline-block">*</span>
      </p>
        <div v-for="v in leaveState.list" :key="v.key">
          <div style=" display: flex; align-items: center;margin-bottom: 10px;">
            <el-input
                v-model="v.title"
                placeholder="请输入建议标题"
            />
            <el-button
              type="text"
              style="margin-left: 20px; color: #ff554f"
              @click="delIntroduce(v.key)"
              >删除</el-button
            >
          </div>
            <el-input
                style="margin-bottom: 15px;"
                v-model="v.value"
                :maxlength="500"
                :rows="4"
                type="textarea"
                placeholder="建议示例：一、多吃蔬菜；二、多喝热水"
            />
        </div>

      <el-button size="small" @click="addIntroduce"
        >添加建议</el-button
      >
    </div>
    <div class="footer">
        <!-- <el-button @click="close"
          >取消</el-button
        > -->
        <el-button
          size="small"
          type="primary"
          @click="submit"
          >提交</el-button
        >
    </div>
  </div>
</template>

<script setup>
import { ref, reactive  } from "vue";
import {useStore} from 'vuex'
import { ElMessage  } from 'element-plus';
import { CODE } from "@/config"
import ossUpload, { createNewFileName } from '@/utils/ossInfo'
import { Plus } from "@element-plus/icons";


const props = defineProps({
  id: [String, Number],
  subjectId: String,
  type: String
})
const emits = defineEmits(['changeCenterDialogVisible2'])
const store = useStore()

const mbtiRef = ref()

const state = reactive({
  dimensionList: [],
  dimensionTotal: 0,
  dimensionPage: 1,
  fileList: [],
  name: '',
})
const leaveState = reactive({
    title:'',
    id:'',
    pass:0,
    introduce:'',
    list:[
        { title: "", value:'', key: 10 }
    ],
    status: 1,
    pic: '',
    scales: []
})

const getSubjectscaleList = () => {
  let data = {
    subjectId: props.subjectId,
    page: state.dimensionPage,
    size: 4,
    status: 1,
  };
  if (state.dimensionList.length > state.dimensionTotal) return
  store.dispatch("manager/subjectscaleList", data).then((res) => {
    if (res.code == 0) {
      state.dimensionPage++
      const vals = (res.data.values || []).map(ele => {
        const scaleItemId = (state.scales?.find(i => i.scaleId === ele.id) || {}).scaleItemId
        return {
          ...ele,
          checkedScale: scaleItemId ? [scaleItemId] : []
        }
      })
      state.dimensionList = [
        ...state.dimensionList,
        ...vals
      ];
      state.dimensionTotal = res.data.total;
    }
  });
}

if (props.id != -1) {
  console.log('编辑等级');
  store.dispatch('manager/subjectscoreInfo',{id:props.id}).then(res=>{
    console.log(res);
    if (res.code == CODE) {
      if (props.type === 'MBTI测评') {
        backfillFormat(res.data)
        getSubjectscaleList()
        return
      }
      leaveState.title = res.data.title
      leaveState.pass = res.data.score
      leaveState.introduce = res.data.description
      leaveState.id = res.data.id
      leaveState.status = res.data.status
      console.log('leaveState.status',leaveState.status)
      let a = JSON.parse(res.data.recommends)
      let arr = []
      if (a) {
        a.forEach((v,i)=>{
          arr.push({title:v.title,value:v.value,key:i+1})
        })
      }
      leaveState.list = arr
    }
  })
} else {
  getSubjectscaleList()
}

const addIntroduce = () => {
    let time = Date.now();
    let obj = { title: "", value:'', key: time };
    leaveState.list.push(obj);
};

const delIntroduce = (id) => {
  if (leaveState.list.length < 2) {
    ElMessage.warning('至少保留一项')
    return
  }
  let index = leaveState.list.findIndex((v) => v.key == id);
  leaveState.list.splice(index, 1);
};
const collect = () => {
  let title = leaveState.title
  let score = leaveState.pass
  let description = leaveState.introduce
  let recommends = []
  if (!title || !description) {
    ElMessage({
      message: '请填写完整',
      type: 'warning',
    })
    return
  }
  for (let i = 0; i < leaveState.list.length; i++) {
    const v = leaveState.list[i];
    if (!v.title || !v.value) {
      ElMessage({
        message: '请填写完整',
        type: 'warning',
      })
      return
    }
    recommends.push({title:v.title,value:v.value})
  }
  return {title,score,description,recommends:JSON.stringify(recommends),status:1,subjectId:props.subjectId}
}
const submit = async() => {
  if (mbtiRef.value && props.type === 'MBTI测评') {
    await mbtiRef.value.validate()
  }
  let data = props.type === 'MBTI测评' ? formatSubmitData() : collect()
  console.log(data);
  
  if (props.id == -1) {
    console.log('新增');
    store.dispatch(`manager/subjectscoreSave`,data).then(res=>{
      console.log(res);
      if (res.code ==  CODE) {
        ElMessage({
          type: "success",
          message: "操作成功",
        });
        emits("changeCenterDialogVisible2")
      }
    })
  }else{
    //为更新
    data.id = props.id
    data.status = leaveState.status
    console.log('更新',data);
    store.dispatch(`manager/subjectscoreUpdate`,data).then(res=>{
      console.log(res);
      if (res.code == CODE) {
        ElMessage({
          type: "success",
          message: "操作成功",
        });
        emits("changeCenterDialogVisible2")
      }
    })
  }
}
// const close = () => {
//   emits("changeCenterDialogVisible2")
// }
  // MBTI
  const scalesValidator = (rule, val, cb) => {
    if (!state.dimensionList.some(ele => ele.checkedScale.length)) {
      return cb(new Error('请选择判定标准'))
    }
    return cb()
  }
  const recommendsValidator = (rule, val, cb) => {
    if (leaveState.list.some(ele => !ele.value || !ele.title)) {
      return cb(new Error('请输入测评建议标题和内容'))
    }
    return cb()
  }
  const mbtiRules = reactive({
    title: [
      { required: true, message: '请输入等级名称', trigger: 'blur'}
    ],
    scales: [
      { required: true, validator: scalesValidator}
    ],
    pic: [
      { required: true, message: '请上传图片' }
    ],
    description: [
      { required: true, message: '请输入结果总描述', trigger: 'blur'}
    ],
    list: [
      { required: true, validator: recommendsValidator }
    ]
  })

  const picUpload = async (option) => {
    const fileName = createNewFileName(option.file.name)
    const result = await ossUpload({
      type: 'image',
      fileName,
      file: option.file
    })
    leaveState.pic = result.url
  }
  
  const beforeUpload = (file) => {
    const reg = /image\/(jpg|jpeg|png)$/
    const isJPG = reg.test(file.type)
    const isLt2M = file.size / 1024 / 1024 < 10
    if (!isJPG) {
      ElMessage({
        type: "error",
        message: "请上传jpg、jpeg、png格式的图片!",
      })
    }
    if (!isLt2M) {
      ElMessage({
        type: "error",
        message: "图片不得超过 10MB!",
      })
    }
    return isJPG && isLt2M
  }

  const formatSubmitData = () => {
    return {
      status: leaveState.status,
      subjectId: props.subjectId,
      title: leaveState.title,
      scales: JSON.stringify(state.dimensionList.map(ele => {
        return {
          scaleId: ele.id,
          scaleItemId: ele.checkedScale.length ? ele.checkedScale[0] : ''
        }
      })),
      pic: leaveState.pic,
      description: leaveState.description,
      recommends: JSON.stringify(leaveState.list.map(({title, value}) => {
        return {
          title,
          value
        }
      }))
    }
  }

  const backfillFormat = (data) => {
    leaveState.id = data.id
    leaveState.status = data.status
    leaveState.title = data.title
    state.scales = JSON.parse(data.scales)
    leaveState.pic = data.pic
    state.fileList = data.pic ? [{url: data.pic, name: 'pic'}] : []
    leaveState.description = data.description
    leaveState.list = JSON.parse(data.recommends).map((ele, index) => {
      return {
        ...ele,
        key: Date.now() + index
      }
    })
  }
</script>

<style lang="scss" scoped>
.home{
    width: 100%;
    // overflow: auto;
    // height: 300px;
    // background-color: violet;
}
.footer{
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.infinite-list {
  height: 200px;
  padding: 20px;
  border: 1px solid #e4e4e4;
}
.count {
  position: absolute;
  right: 10px;
  bottom: 0;
  color: #909399;
  font-size: 12px;
  min-width: 60px;
}
.error-count {
  color: #F56C6C;
}
.textarea-padding {
  padding-bottom: 30px;
}
.list-item {
  margin-bottom:20px;
  &-title {
    display: flex;
    .del-btn {
      margin-left: 10px;
      color: #F56C6C;
    }
  }
  &-desc {
    margin-top: 20px;
  }
}
.hide .el-upload--picture-card {
  display: none;
}
::v-deep(.banner-uploader .el-upload) {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 216px;
  height: 354px;
}
.banner-img {
  width: 216px;
  height: 354px;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 354px;
  text-align: center;
}
</style>
<style>
.mbti-radio-group.el-radio-group {
  font-size: 14px;
  color: #4e4e4e;
}
</style>