<template>
  <div>
    <div v-if="props.type === 'MBTI测评'">
      <el-form
        :model="state"
        label-position="top"
        :rules="mbtiRule"
        ref="mbtiRef"
      >
        <el-form-item label="分维度名称" prop="title">
          <el-input v-model="state.title"></el-input>
        </el-form-item>
        <el-form-item label="子维度" prop="dimen">
          <div class="dimen">
            <span>子维度1</span>
            <el-input v-model="state.l1" style="width: 400px;"
              :maxlength="20"
              show-word-limit
              @blur="handleBlur"
            ></el-input>
          </div>
          <div class="dimen">
            <span>子维度2</span>
            <el-input v-model="state.l2" style="width: 400px;"
              :maxlength="20"
              show-word-limit
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="判定标准" v-if="state.l1 && state.l2">
          <el-radio-group v-model="state.standard" size="large">
            <el-radio-button :label="state.l1 || '子维度1'" />
            <el-radio-button :label="state.l2 || '子维度2'"/>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <el-form
        :model="state.mbtiDimen1"
        label-position="top"
        ref="mbtiDimen1Ref"
        :rules="mbtiDimenRules"
      >
        <div v-show="state.l1 && state.l2 && state.standard === state.l1">
          <el-form-item>
            <div v-infinite-scroll="getMbtiDimen1List" class="infinite-list" style="overflow: auto" :infinite-scroll-immediate="false">
              <div v-for="v in state.mbtiDimen1List"
                :key="v.id"
                class="list-item"
              >
                <div>{{ v.orderNo }}、{{ v.title }}</div>
                <!-- 图片选项 -->
                <el-checkbox-group v-model="v.opts" v-if="v.category === 1">
                  <el-checkbox
                    v-for="item in v.items"
                    :key="item.id"
                    :label="item.id"
                  >
                    <el-image :src="item.title" class="list-item-img" fit="contain"></el-image>
                  </el-checkbox>
                </el-checkbox-group>
                <!-- 非图片选项 -->
                <el-checkbox-group v-model="v.opts" v-else>
                  <el-checkbox
                    v-for="item in v.items"
                    :key="item.id"
                    :label="item.id"
                  >
                   {{item.title}}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="结果描述" prop="title">
            <div class="flex-item">
              <span>标题</span>
              <el-input
                maxlength="10"
                show-word-limit
                v-model="state.mbtiDimen1.title"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item prop="description">
            <div class="flex-item">
              <span>内容</span>
              <el-input
                type="textarea"
                v-model="state.mbtiDimen1.description"
                autosize
                class="textarea-padding"
              ></el-input>
              <span
                :class="{
                  'error-count': state.mbtiDimen1.description?.length > 300
                }"
                class="count"
              >{{ state.mbtiDimen1.description?.length }} / 300</span>
            </div>
          </el-form-item>
        </div>
      </el-form>
      <el-form
        :model="state.mbtiDimen2"
        label-position="top"
        ref="mbtiDimen2Ref"
        :rules="mbtiDimenRules"
      >
        <div v-show="state.l1 && state.l2 && state.standard === state.l2">
          <el-form-item>
            <div v-infinite-scroll="getMbtiDimen2List" class="infinite-list" style="overflow: auto" :infinite-scroll-immediate="false">
              <div v-for="v in state.mbtiDimen2List"
                :key="v.id"
                class="list-item"
              >
                <div>{{ v.orderNo }}、{{ v.title }}</div>
                <!-- 图片选项 -->
                <el-checkbox-group v-model="v.opts" v-if="v.category === 1">
                  <el-checkbox
                    v-for="item in v.items"
                    :key="item.id"
                    :label="item.id"
                  >
                    <el-image :src="item.title" class="list-item-img" fit="contain"></el-image>
                  </el-checkbox>
                </el-checkbox-group>
                <!-- 非图片选项 -->
                <el-checkbox-group v-model="v.opts" v-else>
                  <el-checkbox
                    v-for="item in v.items"
                    :key="item.id"
                    :label="item.id"
                  >
                   {{item.title}}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="结果描述" prop="title">
            <div class="flex-item">
              <span>标题</span>
              <el-input
                maxlength="10"
                show-word-limit
                v-model="state.mbtiDimen2.title"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item prop="description">
            <div class="flex-item">
              <span>内容</span>
              <el-input
                type="textarea"
                autosize
                v-model="state.mbtiDimen2.description"
              ></el-input>
              <span
                :class="{
                  'error-count': state.mbtiDimen2.description?.length > 300
                }"
                class="count"
              >{{ state.mbtiDimen2.description?.length }} / 300</span>
            </div>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="home" v-else>
      <p>
        分维度名称
        <span style="color: red; margin: 10px 0; display: inline-block">*</span>
      </p>
      <el-input v-model="state.title" :maxlength="50" placeholder="请输入名称" />
      <p>
        关联题目
        <span style="color: red; margin: 10px 0; display: inline-block">*</span>
      </p>
      <div class="and-sub" @scroll="scroll">
        <div v-for="v in state.list2" :key="v.id" class="and-subject">
          <el-checkbox
            :checked="isChecked(v)"
            :disabled="!!v.scale && state.title1 != v.scale"
            @change="checkChange(v.id)"
            :label="v.title"
          ></el-checkbox>
          <span>{{ v.scale }}</span>
        </div>
      </div>

      <div v-for="v in state.list" :key="v.key">
        <div style="display: flex; align-items: center; margin-bottom: 10px">
          <span style="width:210px; margin: 0 15px 0 0px; display: inline-block">分维度等级名称</span>
          <el-input style="flex-grow: 1;" v-model="v.title" placeholder="请输入结果描述标题" />
          <span style="width:100px; margin: 0 20px 0 20px; display: inline-block">达标分</span>
          <el-input-number style="width: 120px" v-model="v.pass" :controls="false" />
          <el-button type="text" style="margin-left: 20px; color: #ff554f" @click="delItem(v.key)">删除</el-button>
        </div>
        <p style="margin-bottom: 10px">结果描述</p>
        <el-input
          style="margin-bottom: 15px"
          v-model="v.value"
          :maxlength="500"
          :rows="4"
          type="textarea"
          placeholder="请输入结果描述"
        />
      </div>

      <el-button size="small" @click="addItem">添加分段</el-button>
    </div>
    <div class="footer">
      <!-- <el-button @click="close">取消</el-button> -->
      <el-button type="primary" @click="submit" size="small">提交</el-button>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onBeforeMount } from "vue";
import { useStore } from 'vuex'
import { ElMessage } from "element-plus";
import { CODE } from "@/config"

export default {
  props: ['id', 'subjectId', 'type'],
  setup(props, ctx) {
    console.log('---->', props);
    const store = useStore()
    const state = reactive({
      title: "",
      title1: 1,
      list: [{ title: "", value: "", pass: 0, key: -1 }],
      list2: [],
      selectSubId: [],
      page: 1,
      size: 6,
      totalPage: 2,
      originalId: [], //原选项id
      status: 1,
      // mbti
      name: '',
      l1: '',
      l2: '',
      mbtiDimen1: {
        title: '',
        description: '',
        id: '',
      },
      mbtiDimen2: {
        title: '',
        description: '',
        id: '',
      },
      mbtiDimen1List: [],
      mbtiDimen2List: [],
      mbtiPage1: 1,
      mbtiPage2: 1,
      scaleItems: []
    });
    const addItem = () => {
      let time = Date.now();
      let obj = { title: "", value: "", pass: 0, key: time };
      state.list.push(obj);
    };
    const delItem = (id) => {
      let index = state.list.findIndex((v) => v.key == id);
      state.list.splice(index, 1);
    };
    const checkChange = (id) => {
      let index = state.selectSubId.findIndex((v) => v == id);
      if (index == -1) {
        state.selectSubId.push(id);
      } else {
        state.selectSubId.splice(index, 1);
      }
      console.log(state.selectSubId);
    };
    const scroll = (e) => {
      let element = e.target
      let end = (element.scrollHeight - element.scrollTop) === element.clientHeight
      if (end) {
        console.log('到底啦');
        findSubjectList()
      }
    }
    const findSubjectList = () => {
      let data = {
        subjectId: props.subjectId,
        page: state.page,
        size: state.size,
        status: 1
      };
      console.log(state.page, 'page')
      if (state.page > state.totalPage) {
        console.log('没有更多数据了');
        return
      }
      store.dispatch('manager/subjectitemList', data).then(res => {
        console.log(res);
        if (res.code == CODE) {
          state.list2 = state.list2.concat(res.data.values)
          state.page = state.page + 1
          state.totalPage = Math.ceil(res.data.total / state.size)
        }
      })
    }

    onBeforeMount(() => {
      console.log(props.id)
      if (props.id != -1) {
        findSubjectScaleInfo()
      } else {
        if (props.type !== 'MBTI测评') {
          findSubjectList()
        } else {
          getMbtiDimen1List()
          getMbtiDimen2List()
        }
      }
    })
    const findSubjectScaleInfo = () => {
      store.dispatch("manager/subjectscaleInfo", { id: props.id }).then(res => {
        console.log(res);
        if (res.code == CODE) {
          if (props.type === 'MBTI测评') {
            // 回填数据
            backfillFormat(res.data)
          } else {
            conversion(res.data)
          }
          state.status = res.data.status
          if (props.type !== 'MBTI测评') {
            findSubjectList()
          }
        }
      })
    }
    const isChecked = (v) => {
      var checkId = v.id
      let res = state.selectSubId.some((v) => {
        return v == checkId
      })
      if (!!v.scale && state.title1 != v.scale) {
        return true
      }
      return res
    }
    const conversion = (res) => {
      state.title = res.title
      state.title1 = res.title
      let arr = []
      let arr2 = []
      res.scaleItems.forEach(v => {
        if (v.status == 1) {
          arr.push({ title: v.title, value: v.description, pass: v.score || 0, id: v.id, key: v.id })
        }
        arr2.push(v.id)
      })
      state.originalId = arr2
      state.list = arr.reverse()
      state.selectSubId = JSON.parse(res.items)
    }
    const summary = () => {
      let title = state.title
      let list = state.list
      if (title && list.length > 0) {
        let arr = []
        for (let i = 0; i < list.length; i++) {
          const v = list[i];
          if (!v.title || !v.value) {
            ElMessage({
              type: "warning",
              message: "请填写完整",
            });
            return
          }
          if (v.id) {
            //修改
            arr.push({ title: v.title, score: v.pass, description: v.value, id: v.id, status: 1 })
          } else {
            arr.push({ title: v.title, score: v.pass, description: v.value, status: 1 })
          }
        }
        let data = { title, subjectId: props.subjectId, status: 1, scaleItems: arr, }
        return data
      } else {
        ElMessage({
          type: "warning",
          message: "请填写完整",
        });
        return
      }
    }
    const submit = () => {
      if (props.type === 'MBTI测评') {
        mbtiSubmit()
        return
      }
      let data = summary()
      if (data == undefined) {
        return
      }
      if (state.selectSubId.length > 0) {
        data.items = JSON.stringify(state.selectSubId)
      } else {
        ElMessage({
          type: "warning",
          message: "请填写完整",
        });
        return
      }
      if (props.id != -1) {
        //修改维度
        data.id = props.id
        data.status = state.status
        if (data.scaleItems) {
          let arr = []
          data.scaleItems.forEach(v => {
            arr.push(v.id)
          })
          let lackId = state.originalId.filter(v => {
            return arr.find(i => i == v) == undefined
          })
          console.log(lackId);
          let newArr = []
          lackId.forEach(v => {
            newArr.push({ id: v, status: 2 })
          })
          data.scaleItems = [...data.scaleItems, ...newArr]
        }
      }
      data.total = data.scaleItems.length
      console.log(data);
      store.dispatch('manager/subjectscaleSaves', data).then(res => {
        console.log(res);
        if (res.code == CODE) {
          ElMessage({
            type: "success",
            message: "操作成功",
          });
          ctx.emit("changeCenterDialogVisible3");
        }
      })
    };
    const close = () => {
      ctx.emit("changeCenterDialogVisible3")
    }

    // MBTI 分维度
    // 回填数据
    const backfillFormat = (data) => {
      state.title = data.title
      state.status = data.status
      state.scaleItems = data.scaleItems
      getMbtiDimen1List()
      getMbtiDimen2List()
      data.scaleItems.forEach((ele, index) => {
        state[`l${index + 1}`] = ele.name
        state[`mbtiDimen${index + 1}`] = {
          title: ele.title,
          description: ele.description,
          id: ele.id
        }
        const options = JSON.stringify(ele.options)
        state[`mbtiDimen${index + 1}List`] = state[`mbtiDimen${index + 1}List`].map((item, index) => {
          return {
            ...item,
            opts: options[index].opts
          }
        })
      })
      // 判定标准
      state.standard = state.l1
    }
    const mbtiDimen1Ref = ref()
    const mbtiDimen2Ref = ref()
    const mbtiRef = ref()
    const dimenValidator = (rule, val, cb) => {
      if (!state.l1) {
        return cb(new Error('请输入子维度1'))
      }
      if (!state.l2) {
        return cb(new Error('请输入子维度2'))
      }
      if (state.l1 === state.l2) {
        return cb(new Error('请输入不同内容的子维度'))
      }
      const reg = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/i
      if (!reg.test(state.l1) || !reg.test(state.l2)) {
        return cb(new Error('只能输入字母、数字、汉字'))
      }
      return cb()
    }

    const mbtiRule = reactive({
      title: [
        {required: true, message: '请输入分维度名称', trigger: 'blur'}
      ],
      dimen: [
        { required: true, validator: dimenValidator}
      ]
    })

    const mbtiDimenRules = reactive({
      title: [
        {required: true, message: '请输入结果描述标题', trigger: 'blur'}
      ],
      description: [
        {required: true, message: '请输入结果描述内容', trigger: 'blur'}
      ]
    })

    const handleBlur = () => {
      state.standard = state.l1
    }
    
    const getMbtiDimen1List = () => {
      let data = {
        subjectId: props.subjectId,
        page: state.mbtiPage1,
        size: state.size,
        status: 1,
        options: true
      };
      if (state.mbtiPage1 > state.totalPage) {
        return
      }
      store.dispatch('manager/subjectitemList', data).then(res => {
        if (res.code == CODE) {
          const options = state.scaleItems?.length ? JSON.parse(state.scaleItems[0].options) : []
          const vals = (res.data.values || []).map(ele => {
            const opts = (options.find(opt => {
              return opt.itemId === ele.id
            }) || {}).itemValues || []
            return {
              ...ele,
              opts,
            }
          })
          state.mbtiDimen1List = [
            ...state.mbtiDimen1List,
            ...vals
          ]
          state.mbtiPage1 += 1
          state.totalPage = res.data.totalPage
        }
      })
    }
    const getMbtiDimen2List = () => {
      let data = {
        subjectId: props.subjectId,
        page: state.mbtiPage2,
        size: state.size,
        status: 1,
        options: true
      };

      if (state.mbtiPage2 > state.totalPage) {
        return
      }
      
      store.dispatch('manager/subjectitemList', data).then(res => {
        if (res.code == CODE) {
          const options = state.scaleItems?.length ? JSON.parse(state.scaleItems[1].options) : []
          const vals = (res.data.values || []).map(ele => {
            const opts = (options.find(opt => {
              return opt.itemId === ele.id
            }) || {}).itemValues || []
            return {
              ...ele,
              opts,
            }
          })
          state.mbtiDimen2List = [
            ...state.mbtiDimen2List,
            ...vals
          ]
          state.mbtiPage2 += 1
          state.totalPage = res.data.totalPage
        }
      })
    }

    const mbtiSubmit = async () => {
      if (mbtiRef.value) {
        await mbtiRef.value.validate()
      }
      if (mbtiDimen1Ref.value) {
        await mbtiDimen1Ref.value.validate()
      }
      if (mbtiDimen2Ref.value) {
        await mbtiDimen2Ref.value.validate()
      }
      const opts1 = state.mbtiDimen1List.map(ele => {
        return {
          itemId: ele.id,
          itemValues: ele.opts,
        }
      })
      const opts2 = state.mbtiDimen2List.map(ele => {
        return {
          itemId: ele.id,
          itemValues: ele.opts,
        }
      })
      const scaleItems = [
        {
          name: state.l1,
          options: JSON.stringify(opts1),
          description: state.mbtiDimen1.description,
          title: state.mbtiDimen1.title,
        },
        {
          name: state.l2,
          options: JSON.stringify(opts2),
          description: state.mbtiDimen2.description,
          title: state.mbtiDimen2.title,
        }
      ]
      // 修改scaleItems添加id
      if (props.id != -1) {
        scaleItems[0].id = state.mbtiDimen1.id
        scaleItems[1].id = state.mbtiDimen2.id
      }
      const data = {
        subjectId: props.subjectId,
        title: state.title,
        status: state.status,
        scaleItems,
        total: 2
      }
      if (props.id != -1) {
        //修改维度
        data.id = props.id
      }
      
      store.dispatch('manager/subjectscaleSaves', data).then(res => {
        if (res.code == CODE) {
          ElMessage({
            type: "success",
            message: "操作成功",
          });
          ctx.emit("changeCenterDialogVisible3");
        }
      })
    }

    return {
      state,
      addItem,
      delItem,
      submit,
      close,
      scroll,
      checkChange,
      isChecked,
      props,
      findSubjectList,
      handleBlur,
      mbtiRule,
      getMbtiDimen1List,
      getMbtiDimen2List,
      mbtiDimenRules,
      mbtiRef,
      mbtiDimen1Ref,
      mbtiDimen2Ref
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  // height: 300px;
  // background-color: violet;
}
.footer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.and-sub {
  background: #f2f2f2;
  box-sizing: border-box;
  padding: 0 15px;
  max-height: 210px;
  overflow-y: auto;
  margin-bottom: 20px;
}
.and-subject {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
}

// mbti
.dimen {
  display: flex;
  margin-bottom: 15px;
  span {
    color: #4e4e4e;
    padding-right: 10px;
  }
}
.infinite-list {
  height: 400px;
  padding: 10px;
  border: 1px solid #e4e4e4;
}
.list-item {
  border-bottom: 1px solid #e4e4e4;
  &-img {
    width: 100px;
    height: 100px;
  }
  .el-checkbox {
    height: auto;
  }
}
.flex-item {
  display: flex;
  margin-bottom: 15px;
  span {
    width: 50px;
    color: #4e4e4e;
  }
  .count {
    position: absolute;
    right: 0;
    bottom: 0;
    color: #909399;
    font-size: 12px;
    min-width: 60px;
  }
  .error-count {
    color: #F56C6C;
  }
}
.list-item:last-child {
  border-bottom: none;
}
.textarea-padding {
  padding-bottom: 30px;
}
</style>
