<template>
  <div>
    <img class="back" src="../../assets/back.png" alt @click="back" />
    <!-- <div style="margin-top: 40px">
      <span class="test-p">测评编辑</span>
    </div>-->
    <div class="test-title">
      <div>
        <span>测评ID</span>
        <el-input size="small" style="width: 200px" disabled v-model="state.id" placeholder />
      </div>
      <div>
        <span>测评名称</span>
        <el-input size="small" style="width: 200px" v-model="state.title" placeholder />
      </div>
      <div>
        <span>量表总分</span>
        <el-input
          size="small"
          style="width: 200px"
          disabled
          v-model="state.totalScore"
          placeholder
        />
      </div>
      <div style="margin-right: 0">
        <span>量表类型</span>
        <el-select size="small" v-model="state.type" placeholder="Select" :disabled="true">
          <el-option key="1" label="单维度测评" value="单维度测评"></el-option>
          <el-option key="2" label="多维度测评" value="多维度测评"></el-option>
          <el-option key="3" label="MBTI测评" value="MBTI测评"></el-option>
        </el-select>
      </div>
    </div>
    <el-tabs v-model="state.checkedTitle" @tab-click="handleTitle" class="tabs">
      <el-tab-pane label="基本信息" name="1">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span>基本信息</span>
              <el-button type="success" size="small" @click="saveBase">保存</el-button>
            </div>
          </template>
          <div>
            <div>
              <p>测评的一句话描述</p>
              <el-input
                style="margin-top: 12px"
                v-model="state.input"
                maxlength="50"
                placeholder="字数50字以内"
              />
              <div class="one-upload">
                <div class="upload-div">
                  <p>
                    列表小图
                    <span>(请上传列表小图70*60)</span>
                  </p>
                  <el-upload
                    action
                    :limit="1"
                    :auto-upload="true"
                    :on-remove="removeSmallImg"
                    :file-list="dataSet.samllImg"
                    :http-request="uploadSmallImg"
                    list-type="picture-card"
                    :class="{ hide: dataSet.show1 }"
                  >
                    <el-icon>
                      <plus />
                    </el-icon>
                  </el-upload>
                </div>
                <div class="upload-div">
                  <p>
                    列表大图
                    <span>(请上传列表大图)</span>
                  </p>
                  <el-upload
                    action
                    :limit="1"
                    :auto-upload="true"
                    :on-remove="removeBigImg"
                    :file-list="dataSet.bigImg"
                    :http-request="uploadBigImg"
                    list-type="picture-card"
                    :class="{ hide: dataSet.show2 }"
                  >
                    <el-icon>
                      <plus />
                    </el-icon>
                  </el-upload>
                </div>
              </div>

              <div class="line"></div>
              <div class="upload-div">
                <p>
                  Banner图
                  <span>(请上传300*170测评banner图)</span>
                </p>
                <el-upload
                  action
                  :limit="1"
                  :auto-upload="true"
                  :on-remove="removeBannerImg"
                  :file-list="dataSet.bannerImg"
                  :http-request="uploadBannerImg"
                  list-type="picture-card"
                  :class="{ hide: dataSet.show3 }"
                >
                  <el-icon>
                    <plus />
                  </el-icon>
                </el-upload>
              </div>
              <div class="line"></div>
              <div class="upload-div">
                <p>
                  介绍图
                  <span>(请上传测评介绍图；支持上传多张，根据顺序拼接)</span>
                </p>
                <el-upload
                  action
                  :auto-upload="true"
                  :on-remove="removeIntroduceImg"
                  :file-list="dataSet.introduceImgs"
                  :http-request="uploadIntroduceImg"
                  list-type="picture-card"
                >
                  <el-icon>
                    <plus />
                  </el-icon>
                </el-upload>
                <el-dialog v-model="dataSet.dialogVisible">
                  <img width="100%" :src="dataSet.dialogImageUrl" alt />
                </el-dialog>
              </div>
              <div class="line"></div>
              <div class="upload-div">
                <p>
                  测评须知
                  <span>(请上传测评须知图)</span>
                </p>
                <el-upload
                  action
                  :limit="1"
                  :auto-upload="true"
                  :on-remove="removeNoticeImg"
                  :file-list="dataSet.noticeImg"
                  :http-request="uploadNoticeImg"
                  list-type="picture-card"
                  :class="{ hide: dataSet.show4 }"
                >
                  <el-icon>
                    <plus />
                  </el-icon>
                </el-upload>
                <el-dialog v-model="dataSet.dialogVisible">
                  <img width="100%" :src="dataSet.dialogImageUrl" alt />
                </el-dialog>
              </div>
              <!-- <div class="line"></div> -->
            </div>
          </div>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="题目列表" name="2" :disabled="!state.id">
        <div class="main">
          <div class="right-box">
            <el-button type="primary" size="small" @click="addSubject(-1)">新建题目</el-button>
          </div>
          <el-table
            :data="subjectState.subjectList"
            style="width: 100%"
            highlight-current-row
            border
            size="small"
            :header-cell-style="{ background: '#f7fbff', color: '#000000d9' }"
          >
            <el-table-column prop="id" label="评测ID" align="center" />
            <el-table-column prop="orderNo" label="排序" align="center" />
            <el-table-column prop="title" label="量表题目" align="center" />
            <el-table-column label="题目类型" align="center">
              <template v-slot="scope">
                <span v-if="scope.row.type == 1">{{ scope.row.category == 1 ? "图片" : "文字" }}单选</span>
                <span v-if="scope.row.type == 2">{{ scope.row.category == 1 ? "图片" : "文字" }}多选</span>
                <span v-if="scope.row.type == 3">滑杆</span>
                <span v-if="scope.row.type == 4">判断</span>
                <span v-if="scope.row.type == 5">填空</span>
              </template>
            </el-table-column>
            <el-table-column prop="scale" label="分数维度" align="center" v-if="state.type !== 'MBTI测评'"/>
            <el-table-column label="是否计分" align="center">
              <template v-slot="scope">{{ scope.row.valid == 1 ? "是" : "否" }}</template>
            </el-table-column>
            <el-table-column prop="status" label="状态" align="center">
              <template v-slot="scope">
                <span v-if="scope.row.status == 1">正常</span>
                <span v-if="scope.row.status == 2" style="color: #ff554f">已关闭</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="200">
              <template v-slot="scope">
                <el-button
                  v-if="scope.row.status == 2"
                  type="text"
                  @click="recovery1(scope.row.id)"
                  :style="{ color: '#FC9100' }"
                >恢复</el-button>
                <el-button
                  type="text"
                  :style="{ color: '#71A8FF' }"
                  @click="addSubject(scope.row.id, scope.row.orderNo, scope.row.status)"
                >编辑</el-button>
                <el-button
                  v-if="scope.row.status == 1"
                  type="text"
                  @click="del1(scope.row.id)"
                  :style="{ color: '#F04100' }"
                >删除</el-button>
                <el-button
                  type="text"
                  :style="{ color: '#000000' }"
                  @click="moveSubject(scope.row.id, scope.row.orderNo, 1)"
                >上移</el-button>
                <el-button
                  type="text"
                  :style="{ color: '#000000' }"
                  @click="moveSubject(scope.row.id, scope.row.orderNo)"
                >下移</el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- <el-pagination
        background
        layout="prev, pager, next"
        :total="subjectState.total"
        :page-size="subjectState.size"
        :pager-count="5"
        @current-change="handleCurrentChange1"
        style="margin-top: 20px"
      >
          </el-pagination>-->
          <el-pagination
            class="pagination"
            v-model:currentPage="subjectState.currentPage"
            :page-sizes="[10, 30, 50, 100]"
            :page-size="subjectState.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="subjectState.total"
            background
            @size-change="handleSizeChange1"
            @current-change="handleCurrentChange1"
          ></el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="算法及结果编辑" name="3" :disabled="!state.id">
        <div>
          <el-tabs
            style="margin-top:10px;"
            v-model="algorithmState.checkedSubtitle"
            @tab-click="handleSubtitle"
          >
            <el-tab-pane label="测评总等级" name="1"></el-tab-pane>
            <el-tab-pane label="分维度" name="2"></el-tab-pane>
          </el-tabs>
          <!-- <div class="three-title">
            <div class="t-left" @click="handleSubtitle">
              <div
                id="1"
                :class="
                  algorithmState.checkedSubtitle == 1 ? 'three-text' : null
                "
              >
                测评总等级
              </div>
              <div
                id="2"
                :class="
                  algorithmState.checkedSubtitle == 2 ? 'three-text' : null
                "
              >
                分维度
              </div>
            </div>
            
          </div>-->
          <div v-show="algorithmState.checkedSubtitle == 1" class="main" style="margin-top:0;">
            <div class="right-box">
              <el-button type="primary" size="small" @click="addLeave(-1)">新建总等级</el-button>
            </div>
            <el-table
              :data="algorithmState.rankList"
              style="width: 100%"
              border
              size="small"
              :header-cell-style="{ background: '#f7fbff', color: '#000000d9' }"
              highlight-current-row
            >
              <el-table-column prop="id" label="ID" />
              <el-table-column prop="title" label="等级名" />
              <el-table-column prop="score" label="达标分" v-if="state.type !== 'MBTI测评'">
                <template v-slot="scope">{{ scope.row.score || 0 }}</template>
              </el-table-column>
              <el-table-column prop="status" label="状态">
                <template v-slot="scope">
                  <span v-if="scope.row.status == 1">正常</span>
                  <span v-if="scope.row.status == 2" style="color: #ff554f">已关闭</span>
                </template>
              </el-table-column>
              <el-table-column prop="description" label="结果概述" v-if="state.type !== 'MBTI测评'"/>
              <el-table-column label="操作" align="center">
                <template v-slot="scope">
                  <el-button
                    type="text"
                    v-if="scope.row.status == 2"
                    @click="subjectScoreRecover(scope.row.id)"
                    :style="{ color: '#FC9100' }"
                  >恢复</el-button>
                  <el-button
                    type="text"
                    :style="{ color: '#71A8FF' }"
                    @click="addLeave(scope.row.id)"
                  >编辑</el-button>
                  <el-button
                    type="text"
                    v-if="scope.row.status == 1"
                    @click="delItem1(scope.row.id)"
                    :style="{ color: '#F04100' }"
                  >删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              class="pagination"
              v-model:currentPage="algorithmState.currentPage1"
              :page-sizes="[10, 30, 50, 100]"
              :page-size="algorithmState.size1"
              layout="total, sizes, prev, pager, next, jumper"
              :total="algorithmState.total1"
              background
              @size-change="handleSizeChange2"
              @current-change="handleCurrentChange2"
            ></el-pagination>
          </div>
          <div v-show="algorithmState.checkedSubtitle == 2" class="main" style="margin-top:0;">
            <div class="three-title1">
              <div class="t-right">
                <div class="t-right-left">是否维度外显</div>
                <el-select
                  size="small"
                  style="width: 100px"
                  v-model="state.scaleShow"
                  @change="updateScaleShow"
                >
                  <el-option label="是" value="1"></el-option>
                  <el-option label="否" value="2"></el-option>
                </el-select>
              </div>
            </div>
            <div class="right-box" style="justify-content: space-between; font-size: 14px">
              <p style="line-height:34px;">
                目前计分题目总数:
                <span>{{ algorithmState.totalScore }}</span>
              </p>

              <el-button type="primary" size="small" @click="addDimension(-1)">新建分维度</el-button>
            </div>
            <el-table
              :data="algorithmState.dimensionList"
              style="width: 100%"
              border
              size="small"
              :header-cell-style="{ background: '#f7fbff', color: '#000000d9' }"
              highlight-current-row
            >
              <el-table-column prop="id" label="ID" align="center" />
              <el-table-column prop="title" label="维度名称" align="center" />
              <el-table-column prop="name" label="维度关联题目" align="center" v-if="state.type !== 'MBTI测评'">
                <template v-slot="scope">{{ JSON.parse(scope.row.items) }}</template>
              </el-table-column>
              <el-table-column prop="name" label="子维度" align="center" v-if="state.type === 'MBTI测评'">
                <template v-slot="scope">
                  <div v-for="(item, index) in scope.row.scaleItems"
                    :key="item.id"
                  >{{ index + 1 }}，{{ item.name || '-' }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="total" label="等级数" align="center" v-if="state.type !== 'MBTI测评'"/>
              <el-table-column label="状态" align="center">
                <template v-slot="scope">
                  <span v-if="scope.row.status == 1">正常</span>
                  <span v-if="scope.row.status == 2" style="color: #ff554f">已关闭</span>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="结果概述" align="center" v-if="state.type !== 'MBTI测评'"/>
              <el-table-column label="操作" align="center">
                <template v-slot="scope">
                  <el-button
                    v-if="scope.row.status == 2"
                    type="text"
                    @click="recovery2(scope.row.id)"
                    :style="{ color: '#FC9100' }"
                  >恢复</el-button>
                  <el-button
                    type="text"
                    :style="{ color: '#71A8FF' }"
                    @click="addDimension(scope.row.id, scope.row.status)"
                  >编辑</el-button>
                  <el-button
                    v-if="scope.row.status == 1"
                    type="text"
                    @click="delDimension(scope.row.id)"
                    :style="{ color: '#F04100' }"
                  >删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              class="pagination"
              v-model:currentPage="algorithmState.currentPage2"
              :page-sizes="[10, 30, 50, 100]"
              :page-size="algorithmState.size2"
              layout="total, sizes, prev, pager, next, jumper"
              :total="algorithmState.total2"
              background
              @size-change="handleSizeChange3"
              @current-change="handleCurrentChange3"
            ></el-pagination>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- <div class="two-title">
      <div @click="handleTitle">
        <div id="1" :class="checkedTitle == 1 ? 'test-p' : null">基本信息</div>
        <div id="2" :class="checkedTitle == 2 ? 'test-p' : null">题目列表</div>
        <div id="3" :class="checkedTitle == 3 ? 'test-p' : null">
          算法及结果编辑
        </div>
      </div>
    </div>-->
    <!-- 新建题目弹窗 -->
    <el-drawer
      v-model="subjectState.centerDialogVisible"
      :title="subjectState.title"
      size="660px"
      direction="rtl"
      :before-close="controlCenterDialogVisible"
      destroy-on-close
    >
      <p>
        题目内容
        <span style="color: red; margin: 10px 0; display: inline-block">*</span>
      </p>
      <el-input
        v-model="subjectItem.title"
        :maxlength="80"
        :rows="2"
        type="textarea"
        placeholder="请输入题目"
      />
      <p>
        排序
        <span style="color: red; margin: 10px 0; display: inline-block">*</span>
      </p>
      <el-input-number
        style="width: 100%"
        :min="0"
        v-model="subjectState.orderNo"
        :controls="false"
      />
      <p style="margin: 10px 0; display: inline-block">题目图片</p>
      <el-upload
        action
        :auto-upload="true"
        :on-remove="removeSubjectPhoto"
        :file-list="subjectItem.subjectPhotos"
        :http-request="uploadSubjectPhotos"
        list-type="picture-card"
      >
        <el-icon>
          <plus />
        </el-icon>
      </el-upload>
      <div class="dia1-subType">
        <div class="d-s-div1">
          <p>题目类型</p>
          <el-select style="width: 100%; margin-top: 10px" v-model="subjectItem.type">
            <el-option label="文字选项" value="1"></el-option>
            <el-option label="图片选项" value="2"></el-option>
            <el-option label="填空" value="3" :disabled="state.type === 'MBTI测评'"></el-option>
            <el-option label="滑杆" value="4" :disabled="state.type === 'MBTI测评'"></el-option>
            <el-option label="判断" value="5"></el-option>
          </el-select>
        </div>
        <div style="margin-left: 30px" v-if="subjectItem.type <= 2">
          <p>是否多选</p>
          <el-select style="width: 150px; margin-top: 10px" v-model="subjectItem.isSingle">
            <el-option label="单选" value="1"></el-option>
            <el-option label="多选" value="0" :disabled="state.type === 'MBTI测评'"></el-option>
          </el-select>
        </div>
      </div>
      <p style="margin: 10px 0" v-if="subjectItem.type < 3">
        选项数:
        <span style="color: red">
          {{
            subjectItem.type == 2
              ? subjectItem.option.length
              : subjectItem.option2.length
          }}
        </span>
        <span style="margin-left: 40px">选项最高分:</span>
        <span style="color: red">
          {{
            subjectItem.type == 2 ? subjectItem.max : subjectItem.max2
          }}
        </span>
      </p>
      <!-------------- 图片选择题 ------------->
      <div v-if="subjectItem.type == 2">
        <div v-for="v in subjectItem.option" :key="v.key" class="option-list">
          <div class="o-l-left">
            <div class="input" :style="v.name.length <= 0 ? 'border: 1px solid #7EB0FF;' : ''">
              <span style="color: #7eb0ff" v-if="v.name.length <= 0">点击上传图片</span>
              <span v-if="v.name.length > 0">{{ v.name }}</span>
              <input type="file" :id="v.key" @change="fileChange" name="image" accept="image/*" />
            </div>
            <span class="de-span" v-if="v.name.length > 0">点击修改</span>
          </div>
          <div>
            <span style="margin-right: 15px">分值</span>
            <el-input-number style="width: 120px" v-model="v.score" :controls="false" />
            <el-button
              type="text"
              style="margin-left: 20px; color: #ff554f"
              @click="delOption(v.key)"
            >删除</el-button>
          </div>
        </div>
      </div>
      <!-------------- 文字选择题 ------------->
      <div v-if="subjectItem.type == 1">
        <div v-for="(v, i) in subjectItem.option2" :key="v.key" class="option-list">
          <div class="o-l-left">
            <!-- <span>{{ v.name }}.</span> -->
            <span>{{ String.fromCharCode("A".charCodeAt(0) + i) }}.</span>
            <el-input style="width: 350px; margin-left: 10px" v-model="v.value" placeholder="请输入" />
          </div>
          <div>
            <span style="margin-right: 15px">分值</span>
            <el-input-number style="width: 120px" v-model="v.score" :controls="false" />
            <el-button
              type="text"
              style="margin-left: 20px; color: #ff554f"
              @click="delOption2(v.key)"
            >删除</el-button>
          </div>
        </div>
      </div>
      <!--------------------- 滑杆题 ----------->
      <div v-if="subjectItem.type == 4">
        <div class="option-list" style="line-height: 40px">
          <span>最低分</span>
          <el-input-number
            style="width: 120px"
            v-model="subjectItem.sliderMin.score"
            :controls="false"
          />
          <span>显示文字</span>
          <el-input style="width: 300px" v-model="subjectItem.sliderMin.value" placeholder="请输入" />
        </div>
        <div class="option-list" style="line-height: 40px">
          <span>最高分</span>
          <el-input-number
            style="width: 120px"
            v-model="subjectItem.sliderMax.score"
            :controls="false"
          />
          <span>显示文字</span>
          <el-input style="width: 300px" v-model="subjectItem.sliderMax.value" placeholder="请输入" />
        </div>
        <div v-for="v in subjectItem.option3" :key="v.key" class="option-list">
          <div class="o-l-left">
            <el-input style="width: 170px" v-model="v.name" placeholder="请输入分段名" />
            <span style="margin-left: 20px; margin-right: 15px">达标分</span>
            <el-input-number style="width: 120px" v-model="v.pass" :controls="false" />
          </div>
          <div>
            <span style="margin-right: 15px">分值</span>
            <el-input-number style="width: 120px" v-model="v.score" :controls="false" />
            <el-button
              type="text"
              style="margin-left: 20px; color: #ff554f"
              @click="delOption3(v.key)"
            >删除</el-button>
          </div>
        </div>
      </div>
      <!--------------------- 判断题 ----------->
      <div v-if="subjectItem.type == 5">
        <div class="option-list" style="line-height: 40px">
          <span>A.</span>
          <el-input style="width: 400px" v-model="subjectItem.judgeA.value" placeholder="请输入" />
          <span>分值</span>
          <el-input-number
            style="width: 120px"
            v-model="subjectItem.judgeA.score"
            :controls="false"
          />
        </div>
        <div class="option-list" style="line-height: 40px">
          <span>B.</span>
          <el-input style="width: 400px" v-model="subjectItem.judgeB.value" placeholder="请输入" />
          <span>分值</span>
          <el-input-number
            style="width: 120px"
            v-model="subjectItem.judgeB.score"
            :controls="false"
          />
        </div>
      </div>

      <el-button size="small" @click="addOption2" v-if="subjectItem.type == 1">添加选项</el-button>
      <el-button size="small" @click="addOption" v-if="subjectItem.type == 2">添加选项</el-button>
      <el-button size="small" @click="addOption3" v-if="subjectItem.type == 4">添加分段</el-button>
      <div class="footer">
        <el-button size="small" type="primary" @click="addSubjectItem">提交</el-button>
      </div>
      <!-- </el-dialog> -->
    </el-drawer>
    <!-- 新建等级弹窗  -->
    <!-- <el-dialog
      v-model="subjectState.centerDialogVisible2"
      :title="subjectState.title2"
      width="660px"
      center
      destroy-on-close
    >-->
    <el-drawer
      v-model="subjectState.centerDialogVisible2"
      :title="subjectState.title2"
      size="660px"
      direction="rtl"
      :before-close="changeCenterDialogVisible2"
      destroy-on-close
    >
      <leavePopup
        @changeCenterDialogVisible2="changeCenterDialogVisible2"
        :id="subjectState.subjectUpdateId"
        :subjectId="state.id"
        :type="state.type"
      />
      <!-- </el-dialog> -->
    </el-drawer>
    <!-- 新建维度弹窗 -->
    <!-- <el-dialog
      v-model="subjectState.centerDialogVisible3"
      :title="subjectState.title3"
      width="660px"
      center
      destroy-on-close
    >-->
    <el-drawer
      v-model="subjectState.centerDialogVisible3"
      :title="subjectState.title3"
      size="660px"
      direction="rtl"
      :before-close="changeCenterDialogVisible3"
      destroy-on-close
    >
      <dimensionPopup
        @changeCenterDialogVisible3="changeCenterDialogVisible3"
        :id="subjectState.dimensionUpdateId"
        :subjectId="state.id"
        :type="state.type"
      />
      <!-- </el-dialog> -->
    </el-drawer>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { onBeforeMount, reactive, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ElMessageBox, ElMessage } from "element-plus";
import { Plus } from "@element-plus/icons";
import { CODE } from "@/config";
import leavePopup from "./leavePopup.vue";
import dimensionPopup from "./dimensionPopup.vue";
import ossUpload, { createNewFileName } from '@/utils/ossInfo'
import { getOssInfo } from "../../utils/ossInfo";


export default {
  name: "edit",
  components: { Plus, leavePopup, dimensionPopup },
  setup() {
    const store = useStore();

    const state = reactive({
      id: "", //测评id
      title: "",
      input: "",
      type: "",
      totalScore: "0",
      scaleShow: "1",
      status: "",
      total: "",
      checkedTitle: "1",
    });
    let route = useRoute();
    let router = useRouter();
    if (route.query.id == -1) {
      state.title = route.query.title;
      state.type = route.query.text;
    }
    onBeforeMount(() => {
      // getOssInfo()
      if (route.query.id != -1) {
        state.id = route.query.id;
        Init(route.query.id);
      }
    });
    const Init = (data) => {
      store.dispatch("manager/subjectOneInfo", { id: data }).then((res) => {
        let v = res.data;
        // console.log(res.data.subject);
        state.scaleShow = res.data.scaleShow + "";
        state.total = res.data.total;
        state.status = res.data.status;
        state.title = v.title;
        state.type = v.type;
        state.input = v.description;
        state.totalScore = v.total || 0;
        state.itemNums = v.itemNums
        state.scaleNums = v.scaleNums
        // v.
        dataSet.introduceImgs = processImg(v.imgDesc);
        dataSet.bannerImg = processImg(v.imgBanner);
        dataSet.samllImg = processImg(v.imgList1);
        dataSet.bigImg = processImg(v.imgList2);
        dataSet.noticeImg = processImg(v.imgNotice);
      });
    };
    const processImg = (v) => {
      let arr = [];
      let a;
      try {
        a = JSON.parse(v);
      } catch (error) {
        a = [v];
      }
      if (a instanceof Array) {
        a.forEach((v, i) => {
          arr.push({ url: v, name: i });
        });
      }
      return arr;
    };
    const back = () => {
      router.back();
    };
    const handleTitle = (tab) => {
      algorithmState.checkedSubtitle = "1"
      const id = tab.props.name
      if (id) {
        if (state.id == "") {
          // ElMessage({
          //   message: "请先保存评测",
          //   type: "warning",
          // });
          return;
        }
        // state.checkedTitle= id;
      }
      id == 2 && findSubjectInfo();
      id == 3 && getSubjectScoreList();
    };
    //查询评测题
    const findSubjectInfo = (status) => {
      if (state.id == "") {
        console.log("没有id");
        return;
      }
      let data = {
        subjectId: state.id,
        page: subjectState.page,
        size: subjectState.size,
      };
      if (status == 1) {
        data.status = 1;
      }

      store.dispatch("manager/subjectitemList", data).then((res) => {
        console.log(res);
        if (res.code == CODE) {
          if (status == 1) {
            algorithmState.totalScore = res.data.total * 1;
            return;
          }

          subjectState.subjectList = res.data.values;
          subjectState.total = res.data.total * 1;
        }
      });
    };

    const dataSet = reactive({
      samllImg: [],
      bigImg: [],
      bannerImg: [],
      introduceImgs: [],
      noticeImg: [],
      dialogImageUrl: "",
      dialogVisible: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
    });
    watch(
      () => dataSet.samllImg,
      () => {
        if (dataSet.samllImg.length > 0) {
          dataSet.show1 = true;
        } else {
          dataSet.show1 = false;
        }
      },
      {
        deep: true, // 是否是深度监视, 默认是false
      }
    );
    watch(
      () => dataSet.bigImg,
      () => {
        if (dataSet.bigImg.length > 0) {
          dataSet.show2 = true;
        } else {
          dataSet.show2 = false;
        }
      },
      {
        deep: true, // 是否是深度监视, 默认是false
      }
    );
    watch(
      () => dataSet.bannerImg,
      () => {
        if (dataSet.bannerImg.length > 0) {
          dataSet.show3 = true;
        } else {
          dataSet.show3 = false;
        }
      },
      {
        deep: true, // 是否是深度监视, 默认是false
      }
    );
    watch(
      () => dataSet.noticeImg,
      () => {
        if (dataSet.noticeImg.length > 0) {
          dataSet.show4 = true;
        } else {
          dataSet.show4 = false;
        }
      },
      {
        deep: true, // 是否是深度监视, 默认是false
      }
    );
    const uploadSmallImg = async (files) => {
      let file = files.file;
      const fileName = createNewFileName(file.name)
      const result = await ossUpload({
        type: 'image',
        fileName,
        file: file
      })
      // let result = await store.dispatch("manager/oss", file);
      if (result) {
        dataSet.samllImg.push({ name: file.name, url: result.url });
      }
    };
    const removeSmallImg = (file) => {
      dataSet.samllImg.splice(
        dataSet.samllImg.findIndex((v) => v.url == file.url),
        1
      );
    };
    const uploadBigImg = async (files) => {
      let file = files.file;
      const fileName = createNewFileName(file.name)
      const result = await ossUpload({
        type: 'image',
        fileName,
        file: file
      })
      if (result) {
        dataSet.bigImg.push({ name: file.name, url: result.url });
      }
    };
    const removeBigImg = (file) => {
      dataSet.bigImg.splice(
        dataSet.bigImg.findIndex((v) => v.url == file.url),
        1
      );
    };

    const uploadBannerImg = async (files) => {
      let file = files.file;
      const fileName = createNewFileName(file.name)
      const result = await ossUpload({
        type: 'image',
        fileName,
        file: file
      })
      if (result) {
        dataSet.bannerImg.push({ name: file.name, url: result.url });
      }
    };
    const removeBannerImg = (file) => {
      dataSet.bannerImg.splice(
        dataSet.bannerImg.findIndex((v) => v.url == file.url),
        1
      );
    };

    const uploadIntroduceImg = async (files) => {
      let file = files.file;
      const fileName = createNewFileName(file.name)
      const result = await ossUpload({
        type: 'image',
        fileName,
        file: file
      })
      if (result) {
        dataSet.introduceImgs.push({ name: file.name, url: result.url });
      }
    };
    const removeIntroduceImg = (file) => {
      dataSet.introduceImgs.splice(
        dataSet.introduceImgs.findIndex((v) => v.url == file.url),
        1
      );
    };

    const uploadNoticeImg = async (files) => {
      let file = files.file;
      const fileName = createNewFileName(file.name)
      const result = await ossUpload({
        type: 'image',
        fileName,
        file: file
      })
      if (result) {
        dataSet.noticeImg.push({ name: file.name, url: result.url });
      }
    };
    const removeNoticeImg = (file) => {
      dataSet.noticeImg.splice(
        dataSet.noticeImg.findIndex((v) => v.url == file.url),
        1
      );
    };
    const filterUrl = (a) => {
      let arr = [];
      a.forEach((v) => {
        arr.push(v.url);
      });
      if (arr.length == 0) {
        return false;
      }
      return arr;
    };
    const saveBase = () => {
      const { samllImg, bigImg, bannerImg, introduceImgs, noticeImg } = dataSet;
      const { id, title, type, input, scaleShow, status, total, itemNums, scaleNums } = state;
      if (!title || !type || !input) {
        ElMessage({
          message: "请填写完整",
          type: "warning",
        });
        return;
      }
      let res1 = filterUrl(samllImg);
      let res2 = filterUrl(bigImg);
      let res3 = filterUrl(bannerImg);
      let res4 = filterUrl(introduceImgs);
      let res5 = filterUrl(noticeImg);
      // console.log(res1,res2,res3,res4,res5);
      if (!res1 || !res2 || !res3 || !res4 || !res5) {
        ElMessage({
          message: "请填写完整",
          type: "warning",
        });
        return;
      }
      let data = {
        title,
        type,
        itemNums,
        description: input,
        imgBanner: res3[0],
        imgList1: res1[0],
        imgList2: res2[0],
        imgDesc: JSON.stringify(res4),
        imgNotice: res5[0],
        scaleNums
      };
      id ? (data = { id, scaleShow, status, total, ...data }) : null;
      console.log(data);
      id ? updateSubject(data) : addOneSubject(data);
    };
    const updateScaleShow = (v) => {
      console.log(v);
      console.log(state.scaleShow);
      saveBase();
    };
    const updateSubject = (data) => {
      console.log("更新");
      store.dispatch("manager/subjectUpdate", data).then((res) => {
        console.log(res);
        if (res.code === CODE) {
          state.scaleShow = res.data.scaleShow + "";
          ElMessage({
            message: "操作成功",
            type: "success",
          });
        }
      });
    };
    const addOneSubject = (data) => {
      console.log("添加");
      store.dispatch("manager/subjectSave", data).then((res) => {
        console.log(res);
        if (res.code === CODE) {
          state.id = res.data.id;
          state.scaleShow = res.data.scaleShow + "";
          state.total = res.data.total;
          state.status = res.data.status;
          ElMessage({
            message: "操作成功",
            type: "success",
          });
        }
      });
    };
    //预览图片-----------
    const handlePictureCardPreview = (file) => {
      dataSet.dialogImageUrl = file.url;
      dataSet.dialogVisible = true;
    };
    //----------------题目列表---------------
    const subjectState = reactive({
      subjectList: [],
      page: 1,
      size: 10,
      total: 0,
      subjectUpdateId: -1,
      dimensionUpdateId: -1,
      centerDialogVisible: false,
      title: "",
      centerDialogVisible2: false,
      title2: "",
      centerDialogVisible3: false,
      title3: "",
      currentPage: 1,
      orderNo: 0,
      status: 1,
    });
    const changeCenterDialogVisible2 = () => {
      getSubjectScoreList();
      subjectState.centerDialogVisible2 = false;
    };
    const changeCenterDialogVisible3 = () => {
      getSubjectscaleList();
      subjectState.centerDialogVisible3 = false;
    };
    const handleCurrentChange1 = (v) => {
      subjectState.page = v;
      findSubjectInfo();
    };
    const handleSizeChange1 = (val) => {
      subjectState.size = val;
      console.log(val);
    };
    const subjectscoreClose = (id) => {
      store.dispatch("manager/subjectscoreClose", id).then((res) => {
        if (res.code == CODE) {
          console.log(res);
          getSubjectScoreList();
          ElMessage({
            type: "success",
            message: "删除成功",
          });
        }
      });
    };
    const delItem1 = (v) => {
      console.log(v);
      ElMessageBox.confirm("确认要删除吗?", "确认删除", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          subjectscoreClose(v);
        })
        .catch(() => { });
    };
    const delDimensionReq = (id) => {
      store.dispatch("manager/subjectscaleClose", id).then((res) => {
        console.log(res);
        if (res.code == CODE) {
          getSubjectscaleList();
          ElMessage({
            type: "success",
            message: "删除成功",
          });
        }
      });
    };
    const delDimension = (v) => {
      console.log(v);
      ElMessageBox.confirm("确认要删除吗?", "确认删除", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delDimensionReq(v);
        })
        .catch(() => { });
    };

    const findSubjectItemInfo = (id, sort) => {
      store.dispatch("manager/subjectitemInfo", { id }).then((res) => {
        console.log(res);
        if (res.code == CODE) {
          initSubjectItem();
          subjectItem.id = res.data.id;
          // subjectItem.id = res.data.item.id
          let opts = res.data.items;
          if (opts) {
            let arr = [];
            let status1 = [];
            opts.forEach((v) => {
              arr.push(v.id);
              if (v.status == 1) {
                status1.push(v);
              }
            });
            subjectItem.originalId = arr;
            res.data.items = status1;
          }
          // console.log(subjectItem.originalId);
          conversionSubjectItem(res.data, sort);
          if (sort == "sort") {
            return;
          }
          subjectState.centerDialogVisible = true;
        }
      });
    };
    const addSubjectPhotos = (v) => {
      console.log("有题目图片");
      let arr = [];
      v.forEach((v, i) => {
        arr.push({ name: i + 1, url: v });
      });
      subjectItem.subjectPhotos = arr;
    };
    const conversionSubjectItem = (value, sort) => {
      subjectItem.id = value.id;
      subjectItem.title = value.title;
      value.imgUrl != '' && addSubjectPhotos(JSON.parse(value.imgUrl));
      if (value.type == 1 || value.type == 2) {
        //单选
        value.type == 1
          ? (subjectItem.isSingle = "1")
          : (subjectItem.isSingle = "0");
        if (value.items) {
          if (value.items[0].category == 1) {
            let arr = [];
            //图片选项
            subjectItem.type = "2";
            value.items.forEach((v, i) => {
              arr.push({
                url: v.title,
                name: v.fileName,
                score: v.score || 0,
                key: i + 1,
                id: v.id,
              });
            });
            subjectItem.option = arr;
          } else {
            let arr = [];
            //文字选项
            subjectItem.type = "1";
            value.items.forEach((v, i) => {
              arr.push({
                value: v.title.slice(2),
                name: v.title.slice(0, 1),
                score: v.score || 0,
                key: i + 1,
                id: v.id,
              });
            });
            subjectItem.option2 = arr;
            subjectItem.tip = arr[arr.length - 1].name.charCodeAt(0) - 65;
          }
        }
      }
      if (value.type == 3) {
        //滑杆
        subjectItem.type = "4";
        subjectItem.sliderMin.value = value.minDesc;
        subjectItem.sliderMin.score = value.minScore || 0;
        subjectItem.sliderMax.value = value.maxDesc;
        subjectItem.sliderMax.score = value.maxScore || 0;
        let arr = [];
        value.items.forEach((v, i) => {
          arr.push({
            name: v.title,
            pass: v.maxScore || 0,
            key: i + 1,
            id: v.id,
            score: v.score || 0,
          });
        });
        subjectItem.option3 = arr;
      }
      if (value.type == 4) {
        //判断
        subjectItem.type = "5";
        subjectItem.judgeA.value = value.items[0].title.slice(2);
        subjectItem.judgeB.value = value.items[1].title.slice(2);
        subjectItem.judgeA.score = value.items[0].score || 0;
        subjectItem.judgeB.score = value.items[1].score || 0;
      }
      if (value.type == 5) {
        //填空
        subjectItem.type = "3";
      }
      if (sort == "sort") {
        console.log("是排序");
        //
        addSubjectItem();
      }
    };
    const update1 = (id, sort) => {
      findSubjectItemInfo(id, sort);
    };
    const delReq1 = (id) => {
      store.dispatch("manager/subjectitemClose", id).then((res) => {
        console.log(res);
        if (res.code == CODE) {
          findSubjectInfo();
          ElMessage({
            type: "success",
            message: "操作成功",
          });
        }
      });
    };
    const del1 = (id) => {
      ElMessageBox.confirm("确认删除吗?", "确认删除", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delReq1(id);
        })
        .catch(() => { });
    };
    const subjectitemRecoverReq = (id) => {
      console.log(id);
      store.dispatch("manager/subjectitemRecover", id).then((res) => {
        console.log(res);
        if (res.code == CODE) {
          findSubjectInfo();
          ElMessage({
            type: "success",
            message: "操作成功",
          });
        }
      });
    };
    const moveSubject = (id, orderNo, i) => {
      console.log(id, orderNo);
      let move;
      if (i == 1) {
        //上移
        if (orderNo <= 1) {
          return;
        }
        console.log("上移");
        move = orderNo - 1;
      } else {
        console.log("下移");
        //下移
        move = orderNo + 1;
      }
      subjectState.orderNo = move;
      update1(id, "sort");
    };
    const recovery1 = (v) => {
      ElMessageBox.confirm("确认要把该条记录恢复吗?", "确认恢复", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          subjectitemRecoverReq(v);
        })
        .catch(() => { });
    };
    const subjectScoreRecover = (v) => {
      ElMessageBox.confirm("确认要把该条记录恢复吗?", "确认恢复", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          subjectscoreRecoverReq(v);
        })
        .catch(() => { });
    };
    //总等级列表恢复
    const subjectscoreRecoverReq = (id) => {
      store.dispatch("manager/subjectscoreRecover", id).then((res) => {
        console.log(res);
        if (res.code == CODE) {
          getSubjectScoreList();
          ElMessage({
            type: "success",
            message: "操作成功",
          });
        }
      });
    };
    const RecDimensionReq = (id) => {
      store.dispatch("manager/subjectscaleRecover", id).then((res) => {
        console.log(res);
        if (res.code == CODE) {
          getSubjectscaleList();
          ElMessage({
            type: "success",
            message: "恢复成功",
          });
        }
      });
    };
    const recovery2 = (id) => {
      ElMessageBox.confirm("确认要把该条记录恢复吗?", "确认恢复", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          RecDimensionReq(id);
        })
        .catch(() => { });
    };
    const controlCenterDialogVisible = () => {
      initSubjectItem();
      subjectState.centerDialogVisible = !subjectState.centerDialogVisible;
    };
    const addSubject = (v, orderNo, status) => {
      subjectState.orderNo = 0;
      if (v == -1) {
        subjectState.title = "新建题目";
      } else {
        subjectState.title = "编辑题目";
        subjectState.orderNo = orderNo;
        subjectState.status = status;
        console.log(v, orderNo, status);
        update1(v);
      }
      controlCenterDialogVisible();
    };
    const addLeave = (v) => {
      if (v == -1) {
        subjectState.title2 = "新建总等级";
      } else {
        subjectState.title2 = "编辑总等级";
      }
      subjectState.centerDialogVisible2 = true;
      subjectState.subjectUpdateId = v;
    };
    const addDimension = (v) => {
      if (v == -1) {
        subjectState.title3 = "新建分维度";
      } else {
        subjectState.title3 = "编辑分维度";

      }
      subjectState.centerDialogVisible3 = true;
      subjectState.dimensionUpdateId = v;
    };
    //-----------算法-----------
    const algorithmState = reactive({
      value: "是",
      checkedSubtitle: "1",
      rankList: [],
      dimensionList: [],
      totalScore: 0,
      page1: 1,
      size1: 10,
      total1: 0,
      page2: 1,
      size2: 10,
      total2: 0,
      currentPage1: 1,
      currentPage2: 1,
    });
    const handleSubtitle = (e) => {
      // const id = e.target.id;
      const id = e.props.name
      console.log(id);
      if (id) {
        // algorithmState.checkedSubtitle = id;
        // console.log(id);
        if (id == 1) {
          getSubjectScoreList();
        }
        if (id == 2) {
          console.log(id);
          getSubjectscaleList();
          findSubjectInfo(1);
        }
      }
    };
    const getSubjectscaleList = () => {
      let data = {
        subjectId: state.id,
        page: algorithmState.page2,
        size: algorithmState.size2,
      };
      store.dispatch("manager/subjectscaleList", data).then((res) => {
        console.log(res);
        if (res.code == CODE) {
          algorithmState.dimensionList = res.data.values;
          algorithmState.total2 = res.data.total * 1;
          // algorithmState.totalScore = res.total * 1
        }
      });
    };
    const getSubjectScoreList = () => {
      let data = {
        subjectId: state.id,
        page: algorithmState.page1,
        size: algorithmState.size1,
      };
      store.dispatch("manager/subjectscoreList", data).then((res) => {
        console.log(res);
        if (res.code == CODE) {
          algorithmState.rankList = res.data.values;
          algorithmState.total1 = res.data.total * 1
        }
      });
    };
    // algorithmState.size1
    const handleCurrentChange2 = (v) => {
      algorithmState.page1 = v;

      getSubjectScoreList();
    };
    const handleCurrentChange3 = (v) => {
      algorithmState.page2 = v;
      getSubjectscaleList();
    };
    const handleSizeChange2 = (val) => {
      algorithmState.size1 = val;
      console.log(val);
    };
    const handleSizeChange3 = (val) => {
      algorithmState.size2 = val;
      console.log(val);
    };
    const subjectItem = reactive({
      id: "", //修改题目的id
      title: "", //题目标题
      subjectPhotos: [], //题目图片
      type: "1", //1:文字选项,2:图片选项,3:填空,4:滑杆,5:判断
      isSingle: "1", //1:单选 , 0:多选
      option: [{ url: "", name: "", score: 0, key: 1 }], //图片选项
      option2: [{ name: "A", value: "", score: 0, key: 1 }], //文字选项
      option3: [{ name: "", pass: 0, score: 0, key: 1 }], //滑杆分段
      sliderMin: { score: 0, value: "" }, //滑杆最低
      sliderMax: { score: 0, value: "" }, //滑杆最高
      tip: 0,
      max: 0, //图片选项最高分
      max2: 0, //文字选项最高分
      judgeA: { score: 0, value: "" }, //判断A
      judgeB: { score: 0, value: "" }, //判断B
      originalId: [], //原选项id
    });
    const initSubjectItem = () => {
      subjectItem.id = "";
      subjectItem.title = "";
      subjectItem.subjectPhotos = [];
      (subjectItem.type = "1"),
        (subjectItem.isSingle = "1"),
        (subjectItem.option = [{ url: "", name: "", score: 0, key: 1 }]),
        (subjectItem.option2 = [{ name: "A", value: "", score: 0, key: 1 }]),
        (subjectItem.option3 = [{ name: "", pass: 0, score: 0, key: 1 }]),
        (subjectItem.sliderMin = { score: 0, value: "" }),
        (subjectItem.sliderMax = { score: 0, value: "" }),
        (subjectItem.tip = 0),
        (subjectItem.judgeA = { score: 0, value: "" }),
        (subjectItem.judgeB = { score: 0, value: "" });
      (subjectItem.id = ""), (subjectItem.originalId = []);
    };
    watch(
      () => subjectItem.option,
      () => {
        let arr = JSON.parse(JSON.stringify(subjectItem.option));
        let res = arr.sort((a, b) => {
          return b.score - a.score;
        });
        if (res.length > 0) {
          subjectItem.max = res[0].score;
        } else {
          subjectItem.max = 0;
        }
      },
      {
        immediate: false, // 是否初始化立即执行一次, 默认是false
        deep: true, // 是否是深度监视, 默认是false
      }
    );
    watch(
      () => subjectItem.option2,
      () => {
        let arr = JSON.parse(JSON.stringify(subjectItem.option2));
        let res = arr.sort((a, b) => {
          return b.score - a.score;
        });
        if (res.length > 0) {
          subjectItem.max2 = res[0].score;
        } else {
          subjectItem.max2 = 0;
        }
      },
      {
        immediate: false, // 是否初始化立即执行一次, 默认是false
        deep: true, // 是否是深度监视, 默认是false
      }
    );
    const uploadSubjectPhotos = async (files) => {
      let file = files.file;
      const fileName = createNewFileName(file.name)
      const result = await ossUpload({
        type: 'image',
        fileName,
        file: file
      })
      if (result) {
        subjectItem.subjectPhotos.push({ name: file.name, url: result.url });
      }
    };
    const removeSubjectPhoto = (file) => {
      subjectItem.subjectPhotos.splice(
        subjectItem.subjectPhotos.findIndex((v) => v.url == file.url),
        1
      );
    };
    const addOption = () => {
      let time = Date.now();
      let obj = { url: "", name: "", score: 0, key: time };
      subjectItem.option.push(obj);
    };
    const delOption = (id) => {
      let index = subjectItem.option.findIndex((v) => v.key == id);
      console.log(index);
      subjectItem.option.splice(index, 1);
    };
    const addOption2 = () => {
      let time = Date.now();
      let num = String.fromCharCode("B".charCodeAt(0) + subjectItem.tip);
      let obj = { name: num, value: "", score: 0, key: time };
      subjectItem.tip += 1;

      subjectItem.option2.push(obj);
    };
    const delOption2 = (id) => {
      let index = subjectItem.option2.findIndex((v) => v.key == id);
      console.log(index);
      subjectItem.option2.splice(index, 1);
    };
    const addOption3 = () => {
      let time = Date.now();
      let obj = { name: "", pass: 0, score: 0, key: time };
      subjectItem.option3.push(obj);
    };
    const delOption3 = (id) => {
      let index = subjectItem.option3.findIndex((v) => v.key == id);
      console.log(index);
      subjectItem.option3.splice(index, 1);
    };
    const fileChange = async (e) => {
      const file = e.target.files[0];
      const id = e.target.id;
      if (file) {
        const fileName = createNewFileName(file.name)
        const result = await ossUpload({
          type: 'image',
          fileName,
          file: file
        })
        // let result = await store.dispatch("manager/oss", file);
        console.log(result);
        let index = subjectItem.option.findIndex((v) => v.key == id);
        subjectItem.option[index].name = file.name;
        subjectItem.option[index].url = result.url;
        // console.log(index);
        console.log(subjectItem.option);
      }
    };

    const addSubjectOption = (v) => {
      let arr = [];
      try {
        //新建判断选项
        if (v == 5) {
          let AValue = subjectItem.judgeA.value;
          let AScore = subjectItem.judgeA.score;
          let idA = subjectItem.judgeA.id;
          let BValue = subjectItem.judgeB.value;
          let BScore = subjectItem.judgeB.score;
          let idB = subjectItem.judgeB.id;

          if (!AValue || !BValue) {
            throw new Error("没填完");
          }
          arr = [
            { category: 2, title: "A." + AValue, score: AScore, status: 1 },
            { category: 2, title: "B." + BValue, score: BScore, status: 1 },
          ];
          if (idA) {
            arr[0].id = idA;
          }
          if (idB) {
            arr[0].id = idB;
          }
        }
        if (v == 4) {
          //新建滑杆选项
          for (let i = 0; i < subjectItem.option3.length; i++) {
            const v = subjectItem.option3[i];
            if (!v.name) {
              throw new Error("没填完");
            }
            if (v.id) {
              arr.push({
                category: 2,
                title: v.name,
                score: v.score,
                maxScore: v.pass,
                status: 1,
                id: v.id,
              });
            } else {
              arr.push({
                category: 2,
                title: v.name,
                score: v.score,
                maxScore: v.pass,
                status: 1,
              });
            }
          }
        }
        if (v == 1) {
          //新建文字选项
          for (let i = 0; i < subjectItem.option2.length; i++) {
            const v = subjectItem.option2[i];
            if (!v.value) {
              throw new Error("没填完");
            }
            if (v.id) {
              // arr.push({category:2,title:`${v.name}.${v.value}`,score:v.score,status:1,id:v.id})
              arr.push({
                category: 2,
                title: `${String.fromCharCode("A".charCodeAt(0) + i)}.${v.value
                  }`,
                score: v.score,
                status: 1,
                id: v.id,
              });
            } else {
              // arr.push({category:2,title:`${v.name}.${v.value}`,score:v.score,status:1})
              arr.push({
                category: 2,
                title: `${String.fromCharCode("A".charCodeAt(0) + i)}.${v.value
                  }`,
                score: v.score,
                status: 1,
              });
            }
          }
        }
        if (v == 2) {
          //新建图片选项
          for (let i = 0; i < subjectItem.option.length; i++) {
            const v = subjectItem.option[i];
            if (!v.url) {
              throw new Error("没填完");
            }
            if (v.id) {
              arr.push({
                category: 1,
                title: v.url,
                score: v.score,
                status: 1,
                fileName: v.name,
                id: v.id,
              });
            } else {
              arr.push({
                category: 1,
                title: v.url,
                score: v.score,
                status: 1,
                fileName: v.name,
              });
            }
            // arr.push({category:1,title:v.url,score:v.score,status:1})
          }
        }
        return arr;
      } catch (error) {
        ElMessage({
          message: "请填写完整",
          type: "warning",
        });
      }
    };

    const subjectClass = () => {
      let title = subjectItem.title;
      let imgUrl = JSON.stringify(filterUrl(subjectItem.subjectPhotos));
      let isSingle = subjectItem.isSingle;
      if (!title) {
        ElMessage({
          message: "请填写题目",
          type: "warning",
        });
        return;
      }
      let data = {
        title
      };
      if (imgUrl != "false") {
        data.imgUrl = imgUrl;
      }
      if (subjectItem.type == 1) {
        //选项为文字
        let res = addSubjectOption(subjectItem.type);
        data = { category: 2, type: isSingle == "1" ? "1" : "2", items: res, ...data };
      }
      if (subjectItem.type == 2) {
        //选项为图片
        let res = addSubjectOption(subjectItem.type);
        data = { category: 1, type: isSingle == "1" ? "1" : "2", items: res, ...data };
      }
      if (subjectItem.type == 3) {
        //填空
        data = { type: "5", ...data };
      }
      if (subjectItem.type == 4) {
        //滑竿
        let maxScore = subjectItem.sliderMax.score;
        let maxDesc = subjectItem.sliderMax.value;
        let minScore = subjectItem.sliderMin.score;
        let minDesc = subjectItem.sliderMin.value;
        if (!maxDesc || !minDesc) {
          ElMessage({
            message: "请填写完整",
            type: "warning",
          });
          return;
        }
        let res = addSubjectOption(subjectItem.type);

        data = {
          type: "3",
          items: res,
          maxDesc,
          maxScore,
          minDesc,
          minScore,
          ...data,
        };
      }
      if (subjectItem.type == 5) {
        //判断题
        let res = addSubjectOption(subjectItem.type);
        data = { type: "4", items: res, ...data };
      }
      return data;
    };
    const addSubjectItem = () => {
      // controlCenterDialogVisible()
      let res = subjectClass();
      console.log(res);
      if (res == undefined) {
        return;
      }
      if (res.items == undefined && res.type != "5") {
        return;
      }
      res.subjectId = state.id;
      // res.type = res.type*1
      res.status = 1;
      res.valid = 1;
      // res.scale = '分维度'
      // res.items = JSON.stringify(res.items)
      if (subjectItem.id !== "") {
        console.log("是修改", subjectItem.id);
        res.status = subjectState.status
        if (subjectItem.type == 3) {
          res.id = subjectItem.id;
        }
        if (res.items) {
          let arr = [];
          res.id = subjectItem.id;
          res.items.forEach((v) => {
            arr.push(v.id);
          });
          let lackId = subjectItem.originalId.filter((v) => {
            return arr.find((i) => i == v) == undefined;
          });
          // console.log(lackId);
          let newArr = [];
          lackId.forEach((v) => {
            newArr.push({ id: v, status: 2 });
          });
          res.items = [...res.items, ...newArr];
        }
      }
      if (subjectState.orderNo != 0) {
        res.orderNo = subjectState.orderNo;
      } else {
        ElMessage({
          message: "请输入排序",
          type: "warning",
        });
        return;
      }
      console.log(res);
      store.dispatch("manager/subjectitemSave", res).then((res) => {
        console.log(res);
        if (res.code == CODE) {
          ElMessage({
            message: "操作成功",
            type: "success",
          });
          findSubjectInfo();
          subjectState.centerDialogVisible = false;
        }
      });
    };
    return {
      state,
      back,
      handleTitle,
      dataSet,
      uploadSmallImg,
      removeSmallImg,
      uploadBigImg,
      removeBigImg,
      uploadBannerImg,
      removeBannerImg,
      uploadIntroduceImg,
      removeIntroduceImg,
      uploadNoticeImg,
      removeNoticeImg,
      saveBase,
      updateScaleShow,
      //------------------
      handlePictureCardPreview,
      subjectState,
      addSubject,
      addLeave,
      addDimension,
      handleCurrentChange1,
      handleSizeChange1,
      handleSizeChange2,
      handleSizeChange3,
      del1,
      update1,
      moveSubject,
      recovery1,
      recovery2,
      delItem1,
      delDimension,
      algorithmState,
      handleSubtitle,
      handleCurrentChange2,
      handleCurrentChange3,
      controlCenterDialogVisible,
      subjectItem,
      subjectScoreRecover,
      addSubjectItem,
      addOption,
      delOption,
      addOption2,
      delOption2,
      addOption3,
      delOption3,
      fileChange,
      uploadSubjectPhotos,
      removeSubjectPhoto,
      changeCenterDialogVisible2,
      changeCenterDialogVisible3,
    };
  },
};
</script>
<style>
.main {
  margin-top: 20px;
  background: white;
}
.hide .el-upload--picture-card {
  display: none;
}
</style>
<style lang="scss" scoped>
* {
  font-size: 14px;
}
.tabs {
  background: white;
  margin-top: 20px;
  padding: 24px;
}
.pagination {
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}
::v-deep .el-drawer__body {
  overflow-y: auto !important;
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.back {
  width: 30px;
  height: 25px;
}
.test-p {
  display: inline-block;
  font-size: 16px;
  color: #222222 !important;
  border-bottom: 2px solid #71a8ff;
}
.test-title {
  margin-top: 20px;
  background: white;
  padding: 24px;
  padding-bottom: 0;
  border-radius: 2px;
  & > div {
    margin-right: 26px;
    display: inline-block;
    margin-bottom: 24px;
    span {
      margin-right: 12px;
    }
    p {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #444444;
      margin-bottom: 12px;
    }
  }
}
.two-title {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  div:first-child {
    display: flex;
    div {
      margin-right: 40px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #888888;
      cursor: pointer;
    }
  }
}
.box-card {
  //   height: 1230px;
  margin-top: 30px;
  .card-header {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    color: #222222;
    align-content: center;
  }
}
.one-upload {
  display: flex;
  div {
    flex-grow: 1;
  }
}
.upload-div {
  & > p {
    margin-top: 20px;
    font-size: 14px;
    color: #222222;
    span {
      font-size: 14px;
      color: #888888;
      margin-left: 6px;
    }
    margin-bottom: 10px;
  }
}
.line {
  width: 100%;
  border-bottom: 1px solid #e4e4e4;
  margin-top: 20px;
}
//---------题目列表------
.right-box {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
//--算法--
// .three-title {
//   margin-top: 30px;
//   line-height: 40px;
//   font-size: 20px;
//   color: #888888;
//   display: flex;
//   justify-content: space-between;
//   .t-left {
//     display: flex;
//     div {
//       margin-right: 40px;
//       cursor: pointer;
//     }
//   }

// }
.three-title1 {
  margin-bottom: 10px;
  line-height: 40px;
  font-size: 20px;
  color: #888888;
  display: flex;
  justify-content: flex-end;
}
.t-right {
  display: flex;
  align-items: center;
  .t-right-left {
    margin-right: 28px;
    color: #222222;
  }
}
.three-text {
  color: #222222 !important;
  height: 28px !important;
  border-bottom: 2px solid #71a8ff;
  display: inline-block;
}
//-----题目弹窗
.dia1-subType {
  margin: 10px 0;
  display: flex;
  .d-s-div1 {
    flex-grow: 1;
  }
}
.option-list {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  .o-l-left {
    display: flex;
    align-items: center;
    text-align: center;
    .de-span {
      color: #7eb0ff;
      text-decoration: underline;
      margin-left: 15px;
    }
  }
  .input {
    width: 120px;
    height: 40px;
    border: 1px solid #e4e4e4;
    box-sizing: border-box;
    position: relative;

    span {
      display: inline-block;
      line-height: 40px;
      text-decoration: none;
      width: 120px;
      height: 40px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    input {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 0;
      opacity: 0;
      width: 210px;
      height: 40px;
    }
  }
}
</style>
